import React, { useRef, useState, useEffect } from "react";
import "./Home.css";
import { Badge, Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../../components/Header/Header";
import heroimage from "../../assets/Home/Heroimage.png";
import { IoIosArrowForward } from "react-icons/io";
import mockup from "../../assets/Home/mockup.png";
import {
  Button,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import laptop from "../../assets/Home/dashboard.png";
import phn from "../../assets/Home/phn.png";
import screen2 from "../../assets/Home/screen2.png";
import { FaArrowTrendUp } from "react-icons/fa6";
import { getDatabase, push, ref, set } from "firebase/database";
import firebaseConfig from "../../config/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/Footer/Footer";
import { Link, animateScroll as scroll } from "react-scroll";
import { Fade, Slide } from "react-awesome-reveal";
function Home() {
  const containerRef = useRef(null);
  const contactFormRef = useRef(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    organization: "",
  });

  // State to manage validation errors
  const [errors, setErrors] = useState({});

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm(formData);
    if (Object.keys(newErrors).length === 0) {
      try {
        const db = getDatabase();
        const formDataRef = ref(db, "formData");
        const newFormRef = push(formDataRef);

        const formDataWithTimestamp = {
          ...formData,
          timestamp: new Date().toLocaleString(),
        };

        await set(newFormRef, formDataWithTimestamp);

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          contact: "",
          organization: "",
        });

        toast.success(
          "Thank you for showing interest, we'll get back to you shortly"
        );
      } catch (error) {}
    } else {
      setErrors(newErrors);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.firstName) errors.firstName = "First name is required";
    if (!data.lastName) errors.lastName = "Last name is required";
    if (!data.email) errors.email = "Email is required";
    const phonePattern = /^(\+1|1)?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (!data.contact) {
      errors.contact = "Contact number is required";
    } else if (!phonePattern.test(data.contact)) {
      errors.contact = "Please enter a valid phone number";
    }
    if (!data.organization)
      errors.organization = "Organization name is required";

    return errors;
  };

  const handleMouseMove = (event) => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const containerCenterX = containerRect.left + containerRect.width / 2;
    const containerCenterY = containerRect.top + containerRect.height / 2;
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    const angleX = (mouseY - containerCenterY) * 0.04;
    const angleY = (mouseX - containerCenterX) * 0.04;

    containerRef.current.style.transform = `perspective(1000px) rotateX(${angleX}deg) rotateY(${angleY}deg)`;
  };

  const handleMouseLeave = () => {
    containerRef.current.style.transform = "none";
  };

  // stepper data
  const steps = [
    {
      label: "Discussion",
      subhead:
        "Engage in meaningful dialogue to thoroughly understand your needs.",
    },
    {
      label: "Requirements",
      subhead:
        "Share your specifications, and let us bring your vision to life.",
    },
    {
      label: "Customization",
      subhead:
        "Tailor our solutions to fit your unique needs and preferences precisely.",
    },
    {
      label: "Delivered",
      subhead:
        "Experience satisfaction as we promptly and efficiently deliver quality solutions.",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const yPos = window.scrollY;
      if (yPos > 100) {
        // Show smooth scroll button
        // You can implement this part based on your UI requirements
      } else {
        // Hide smooth scroll button
        // You can implement this part based on your UI requirements
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToContactUs = () => {
    if (contactFormRef.current) {
      const targetPosition = contactFormRef.current.offsetTop;
      const startPosition = window.pageYOffset;
      const distance = targetPosition - startPosition;
      const duration = 500; // Increased duration for smoother effect
      let start = null;

      function animation(currentTime) {
        if (start === null) start = currentTime;
        const timeElapsed = currentTime - start;
        const run = ease(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
      }

      // Easing function
      function ease(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t * t + b;
        t -= 2;
        return (c / 2) * (t * t * t + 2) + b;
      }

      requestAnimationFrame(animation);
    }
  };

  return (
    <>
      <Container fluid id="home1">
        <Container>
          <Header scrollToContactUs={scrollToContactUs} />
        </Container>
        <Container>
          <Row>
            <Col id="home5" md="6" lg="6" xl="6" sm="12" xs="12">
              <Row>
                <Col>
                  <Slide>
                    <span id="home3"> Revolutionize Healthcare </span>{" "}
                    <span id="home4">
                      {" "}
                      Efficiency Clinical Management Solution!{" "}
                    </span>
                  </Slide>
                </Col>
              </Row>
              <Row>
                <Fade delay={1e3} cascade damping={1e-1}>
                  <Col id="home6">
                    {" "}
                    Streamline operations, enhance patient care, and boost
                    productivity. Discover the power of innovation in healthcare
                    management today!"
                  </Col>
                </Fade>
              </Row>
              <Row>
                <Col>
                  <Stack direction="horizontal" id="home9">
                    <Badge id="home8">
                      {" "}
                      <IoIosArrowForward /> Specimen information
                    </Badge>{" "}
                    <Badge id="home8">
                      {" "}
                      <IoIosArrowForward />
                      Diagnosis ICD-10 Code
                    </Badge>
                  </Stack>
                </Col>
              </Row>
              <Row>
                {" "}
                <Col>
                  <Stack direction="horizontal" id="home9">
                    <Badge id="home8">
                      {" "}
                      <IoIosArrowForward /> Custom drug category
                    </Badge>{" "}
                    <Badge id="home8">
                      {" "}
                      <IoIosArrowForward />
                      Forensic Test-E-Order
                    </Badge>
                  </Stack>
                </Col>
              </Row>
              <Row id="home10">
                {" "}
                <Col>
                  <Button id="home11" onClick={scrollToContactUs}>
                    Register for a Free Trial
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <img src={heroimage} id="home2" />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container>
          <Row id="home17">
            <Col md="6" lg="6" xl="6" sm="12" xs="12">
              <img src={mockup} id="home2" />
            </Col>
            <Col ref={contactFormRef}>
              <Row>
                {" "}
                <Col id="home12"> Contact us</Col>
              </Row>
              <Row>
                {" "}
                <Col>
                  <span id="home13">We're Here to Help </span>{" "}
                  <span id="home14"> Reach Out to Us Today! </span>{" "}
                </Col>
              </Row>
              <form onSubmit={handleSubmit}>
                <Row id="home15">
                  {" "}
                  <Col>
                    {" "}
                    <TextField
                      name="firstName"
                      label="First name"
                      variant="outlined"
                      fullWidth
                      sx={{ maxWidth: "500px" }}
                      className="inputfieldhome"
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      value={formData.firstName}
                      onChange={handleInputChange}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                    />
                  </Col>
                  <Col>
                    {" "}
                    <TextField
                      name="lastName"
                      label="Enter Your Last name"
                      variant="outlined"
                      fullWidth
                      sx={{ maxWidth: "500px" }}
                      className="inputfieldhome"
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      value={formData.lastName}
                      onChange={handleInputChange}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                    />
                  </Col>
                </Row>
                <Row id="home15">
                  <Col>
                    <TextField
                      name="email"
                      id="email-input"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      sx={{
                        maxWidth: "500px",
                        "& .MuiInputBase-root": {
                          fontFamily: "'Fira Sans', sans-serif",
                        },
                        "& .MuiInputLabel-root": {
                          fontFamily: "'Fira Sans', sans-serif",
                        },
                      }}
                      className="inputfieldhome"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </Col>
                  <Col>
                    <TextField
                      name="contact"
                      id="contact-input"
                      label="Contact Number"
                      variant="outlined"
                      fullWidth
                      sx={{
                        maxWidth: "500px",
                        "& .MuiInputBase-root": {
                          fontFamily: "'Fira Sans', sans-serif",
                        },
                        "& .MuiInputLabel-root": {
                          fontFamily: "'Fira Sans', sans-serif",
                        },
                      }}
                      className="inputfieldhome"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+1</InputAdornment>
                        ),
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      value={formData.contact}
                      onChange={handleInputChange}
                      error={!!errors.contact}
                      helperText={errors.contact}
                    />
                  </Col>
                </Row>
                <Row id="home15">
                  {" "}
                  <Col>
                    <TextField
                      name="organization"
                      label="Organization name"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      value={formData.organization}
                      onChange={handleInputChange}
                      error={!!errors.organization}
                      helperText={errors.organization}
                    />
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col>
                    {" "}
                    <Button id="home16" variant="contained" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid id="home18">
        <Container id="home20">
          <Row>
            <Col
              id="home31"
              ref={containerRef}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <img src={laptop} id="home19" />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <Row>
          {" "}
          <Col>
            <Stepper activeStep={4} alternativeLabel>
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Col>
        </Row>
      </Container>
      <Container fluid id="home26">
        <Row id="home22">
          <Col md="6" lg="6" xl="6" sm="12" xs="12" id="home27">
            <Row>
              {" "}
              <Col id="home29"> Key Features</Col>
            </Row>
            <Row>
              {" "}
              <Fade delay={1e3} cascade damping={1e-1}>
                <Col id="home32">
                  {" "}
                  Discover a seamless solution tailored to your clinic's needs.
                  With intuitive features designed to streamline tasks and
                  enhance patient care, our platform revolutionizes clinic
                  management. Experience efficiency and excellence in one
                  comprehensive system.
                </Col>
              </Fade>
            </Row>
            <Row>
              <Col id="home30">
                <ul className="features-list">
                  <Fade delay={1e3} cascade damping={1e-1}>
                    <li>
                      <strong style={{ color: "#00A9BB" }}>
                        Comprehensive Admin Dashboard:
                      </strong>{" "}
                      Streamline daily tasks for doctors, nurses, physicians,
                      lab assistants, and other staff members with an intuitive
                      and customizable interface.
                    </li>
                  </Fade>
                  <Fade delay={1e3} cascade damping={1e-3}>
                    <li>
                      <strong style={{ color: "#00A9BB" }}>
                        User-Specific Customization:
                      </strong>{" "}
                      Tailor the dashboard for different roles, ensuring that
                      each user has access to the tools and information they
                      need most.
                    </li>
                  </Fade>
                  <Fade delay={1e3} cascade damping={1e-3}>
                    <li>
                      <strong style={{ color: "#00A9BB" }}>
                        Efficient Appointment Scheduling:
                      </strong>{" "}
                      Simplify booking and managing appointments to reduce
                      no-shows and optimize patient flow.
                    </li>
                  </Fade>
                  <Fade delay={1e3} cascade damping={1e-3}>
                    <li>
                      <strong style={{ color: "#00A9BB" }}>
                        Seamless Prescription Management:
                      </strong>{" "}
                      Generate prescriptions easily and allow patients to
                      download them via QR code for convenience and accuracy.
                    </li>
                  </Fade>
                  <Fade delay={1e3} cascade damping={1e-3}>
                    <li>
                      <strong style={{ color: "#00A9BB" }}>
                        Integrated Patient Records:
                      </strong>{" "}
                      Access and manage patient information securely, ensuring
                      comprehensive care and easy data retrieval.
                    </li>
                  </Fade>
                  <Fade delay={1e3} cascade damping={1e-3}>
                    <li>
                      <strong style={{ color: "#00A9BB" }}>
                        Real-Time Reporting:
                      </strong>{" "}
                      Generate insightful reports to monitor clinic performance,
                      patient outcomes, and staff productivity.
                    </li>
                  </Fade>
                  <Fade delay={1e3} cascade damping={1e-3}>
                    <li>
                      <strong style={{ color: "#00A9BB" }}>
                        Affordable Pricing:
                      </strong>{" "}
                      Enjoy a feature-rich solution designed to fit within your
                      budget, providing exceptional value for all the
                      functionalities offered.
                    </li>
                  </Fade>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col id="home21">
            {" "}
            <img src={phn} id="home23" />{" "}
          </Col>
        </Row>
      </Container>
      <Container fluid id="home26">
        <Row id="home22">
          <Col id="home24" md="6" lg="6" xl="6" sm="12" xs={{ order: 2 }}>
            {" "}
            <img src={screen2} id="home23" />{" "}
          </Col>

          <Col id="home27" xs={{ order: 1 }} md={{ order: 2 }}>
            <Row id="home25">
              <Col>
                With Dashboard All the data <br /> is in one place
              </Col>
            </Row>

            <Row>
              {" "}
              <Col>
                {" "}
                Medix offers cutting-edge software solutions designed to
                optimize every aspect of your clinic's operations. From
                appointment scheduling to patient records management, experience
                unparalleled efficiency and enhanced patient care with our
                intuitive and robust platform.
              </Col>
            </Row>
            <Row>
              {" "}
              <Col id="home28">
                {" "}
                Know more <FaArrowTrendUp />{" "}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Footer />
      </Container>
    </>
  );
}

export default Home;

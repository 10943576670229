import "./App.css";
import RootRouter from "./navigations/RootRouter";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from "./config/firebase";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const fApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(fApp);

  console.log("analytics", analytics);
  return (
    <>
      <RootRouter />
      <ToastContainer />
    </>
  );
}

export default App;

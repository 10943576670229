import { Container, Navbar, Nav, Button } from "react-bootstrap";
import "./Header.css";
import Logo from "../../assets/Home/Xmedlogo.svg";
import { IoCloseOutline } from "react-icons/io5";
import { IoMenuOutline } from "react-icons/io5";
function Header({ scrollToContactUs }) {
  return (
    <>
      <Navbar id="header1">
        <Container>
          <Navbar.Brand>
            <img src={Logo} alt="Logo" className="logo" />
          </Navbar.Brand>

          <Nav>
            <Nav.Link>
              <Button id="header3" onClick={scrollToContactUs}>
                {" "}
                Contact Us
              </Button>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;

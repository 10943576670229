import React from "react";
import "./Footer.css";
import logo from "../../assets/Home/Xmedlogo.svg";
import {
  FaLinkedinIn,
  FaWhatsapp,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
} from "react-icons/fa";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <img src={logo} alt="Xmed Logo" className="footer-logo" />
          <p>
            Revolutionizing forensic testing with cutting-edge clinical
            management solutions.
          </p>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>
            <FaEnvelope className="icon" />
            <a href="mailto:adil.rashid@thecodekart.com" className="email-link">
              adil.rashid@thecodekart.com
            </a>
          </p>

          <p>
            <FaMapMarkerAlt className="icon" /> JP Nagar 8th phase, Bangalore,
            India, 560076
          </p>
          <div className="social-icons">
            <a
              href="https://www.linkedin.com/in/adil-rashid-090/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <FaLinkedinIn />
            </a>
            <a
              href="https://wa.me/9658375094" // Replace with your WhatsApp number
              target="_blank"
              rel="noopener noreferrer"
              aria-label="WhatsApp"
            >
              <FaWhatsapp />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Xmed. All rights reserved.</p>
        <p>A product by Codekart Solution Pvt. Ltd.</p>
      </div>
    </footer>
  );
}

export default Footer;

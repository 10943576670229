import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavConstants } from "./NavConstants";
import Home from "../pages/Home/Home";

function RootRouter() {
  return (
    <>
      <Router>
        <Routes>
          <Route path={NavConstants.home} element={<Home />} />
        </Routes>
      </Router>
    </>
  );
}

export default RootRouter;

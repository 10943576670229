const firebaseConfig = {
  apiKey: "AIzaSyCTODNUDSM6ukyiI4GK3y39uGgi5RA1m6E",
  authDomain: "med-a9d05.firebaseapp.com",
  projectId: "xmed-a9d05",
  storageBucket: "xmed-a9d05.appspot.com",
  messagingSenderId: "1050340142638",
  appId: "1:1050340142638:web:ac7417930ee5fbe8dfb9bf",
  measurementId: "G-3BJY3MJFVQ",
};

export default firebaseConfig;
